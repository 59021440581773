<template>
  <main class="project">
    <b-container class="px-0" fluid>
      <b-row no-gutters>
        <b-col sm="12" lg="6" class="project__content">
          <section class="text-center">
            <h1 class="main-heading">
              {{ $t("heading.our_works") }}
            </h1>
            <p class="mx-auto main-description" v-html="workDescription">
              <!-- {{ workDescription }} -->
              <!-- We operate as an extension of our client’s company. We invite our
              clients to join us in our design process, participate in iterative
              integration, and support long-term product development roadmaps. -->
            </p>
          </section>
        </b-col>
        <b-col sm="12" lg="6">
          <div class="ourwork-container">
            <b-row
              no-gutters
              class="ourwork-container__wrpper"
              align-v="center"
            >
              <b-col sm="12" md="8" class="mx-auto">
                <b-row no-gutters>
                  <b-col
                    class="p-2"
                    lg="4"
                    sm="6"
                    cols="6"
                    v-for="(item, index) in items"
                    :key="item.id"
                    :class="{
                      'ourwork-container__middle-card': handleMiddleCard(
                        index + 1
                      )
                    }"
                  >
                    <OurWorkItem :item="item" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- <div class="img-wrap  ratio-100">
            <div class="img-content">
              <b-img :src="require('~/assets/imgs/project2.svg')"></b-img>
            </div>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";
import { mapGetters } from "vuex";

export default {
  name: "OurWork",
  components: {
    OurWorkItem: () => import("./OuerWork-item")
  },
  data() {
    return {
      items: [],
      workDescription: ""
    };
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.workDescription = this.settings("home_our_works");
      },
      immediate: true
    }
  },
  mounted() {
    this.handleGetWorks();
  },
  methods: {
    handleMiddleCard(index) {
      if (index % 2 == 0) {
        return true;
      }
    },
    async handleGetWorks() {
      try {
        const {
          data: { works }
        } = await IndexData({ reqName: "works" });
        this.items = works.splice(0, 6);
    
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
